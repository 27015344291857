import React, { useContext, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import Onboarding from '../pages/home/Onboarding';
import Overview from '../pages/home/Overview';
import InboxItem from '../pages/home/InboxItem';
import OpenActions from '../pages/home/OpenActions';
import UpdatePreview from '../pages/home/UpdatePreview';
import { UserContext } from '../context/UserContext';
import axios from 'axios'

export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });

const Home = () => {
    const { user, profile, loggedInCheck } = useContext(UserContext);
    const [wait, setWait] = useState(false);
    const [showModal, setShowModal] = useState(false); // Modal visibility state
    const [formData, setFormData] = useState({}); // Form data state
    let isOpenActionsRendered = false;

    // Open modal if user.referral is not defined
    React.useEffect(() => {
        if (!user.referral) {
            setShowModal(true);
        }
    }, [user]);

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setWait(true);
            try{
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const {data} = await Axios.post('user_referral/create.php',{
                  user_id: user.user_id,
                  referral: formData.source,
                  description: formData.comments
                }).then(response => response.data);
                
                await loggedInCheck();
                
                setWait(false);
                
          
            }
            catch(err){
                setWait(false);
                return {success:0, message:'Server Error!'};
            }
        // Add your form submission logic here (e.g., API call)
        setShowModal(false);
    };

    // Close modal
    // Handle form submission
    const handleClose = async (e) => {
        e.preventDefault();
        setWait(true);
            try{
                const loginToken = localStorage.getItem('loginToken');
                Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
                const {data} = await Axios.post('user_referral/create.php',{
                  user_id: user.user_id,
                  referral: formData.source,
                  description: formData.comments
                  
                }).then(response => response.data);
                
                await loggedInCheck();
                
                setWait(false);
                
          
            }
            catch(err){
                setWait(false);
                return {success:0, message:'Server Error!'};
            }
        // Add your form submission logic here (e.g., API call)
        setShowModal(false);
    };

    return (
        <div id="dashboard">
            <Topbar />
            <Sidebar />
            <div className="board preview">
                <div className="board">
                    {user.has_investments === 'no' ? <Onboarding /> : <Overview />}

                    {profile !== 'none' && profile.map((profile, index) => {
                        if ((user.open_actions === 'yes') && !isOpenActionsRendered) {
                            isOpenActionsRendered = true;
                            return <OpenActions key={index} />;
                        }
                        return null;
                    })}

                    {user.participated && user.participated === 'yes' && <UpdatePreview />}
                </div>
                <InboxItem />
            </div>

            {/* Modal */}
            <Modal show={showModal} onHide={handleClose}  backdrop="static" keyboard={false} >

    <Modal.Body>
    <div className="svg-container mb-4">
    <img src="./assets/img/cap-welcome.svg" className="responsive-svg" />
  </div>
    <h4 className="mb-3">Welkom {(user.id_progress == 'COMPLETED' || user.has_profiles == 'yes') ? 'terug' : 'bij Capler'}! We zijn benieuwd</h4>
        
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-2 " controlId="formSource">
                <Form.Label className="mb-2">Hoe heeft u voor het eerst over ons gehoord?</Form.Label>
                <div className="select-wrapper">
                <Form.Select
                    name="source"
                    value={formData.source || ''}
                    onChange={(e) => {
                        handleChange(e);
                        // Show textarea for specific values
                        const selectedValue = e.target.value;
                        setFormData((prevData) => ({
                            ...prevData,
                            showComments: ["friend_referral", "advertisement", "search_engine", "linkedin","financial_advisor", "other"].includes(selectedValue),
                        }));
                    }}
                    required
                >
                    <option value="" disabled>Kies een optie</option>
                    <option value="friend_referral">Persoonlijke aanbeveling</option>
                    <option value="advertisement">Krant, email, advertentie, blog</option>
                    <option value="search_engine">Zoekmachine (bijv. Google)</option>
                    <option value="linkedin">LinkedIn</option>
                    <option value="excellent">Excellent Fondsen</option>
                    <option value="financial_advisor">Financieel adviseur</option>
                    <option value="other">Anders</option>
                </Form.Select>
                <i class="bi bi-chevron-down"></i>
                </div>
            </Form.Group>

            {formData.showComments && (
                <Form.Group className="mb-2" controlId="formComments">
                    
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="comments"
                        placeholder="Namelijk.. (licht uw antwoord kort toe)"
                        value={formData.comments || ''}
                        onChange={handleChange}
                    />
                </Form.Group>
            )}

            
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button className="btn-sm" variant="link" onClick={handleClose}>
            Overslaan
        </Button>
        <Button className="btn-sm" variant="primary" onClick={handleSubmit}>
            Verzenden
        </Button>
    </Modal.Footer>
</Modal>
        </div>
    );
};

export default Home;