import React  from 'react';
import {useContext, useMemo} from 'react'
import {UserContext} from '../../context/UserContext'
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Breadcrumbs from './Breadcrumbs';
import DocsHeader from './DocsHeader';
import NewContract from '../../documents/NewContract';



const Contracts = () => {
    const { profile } = useContext(UserContext);

    const allInvestments = useMemo(() => {
        return profile?.flatMap(p => p.investments || [])
            .sort((b, a) => new Date(a.investment_created_date) - new Date(b.investment_created_date));
    }, [profile]);

    if (!profile) {
        return <div>Loading...</div>;
    }

    const renderInvestments = () =>
        allInvestments
            .filter(investment => investment.investment_id)
            .map(investment => <NewContract key={investment.investment_id} investment={investment} />);

    return (
        <div id="dashboard">
            <Topbar />
            <Sidebar />
            <div className="board">
                <Breadcrumbs />
                <div className="card card-full">
                    <div className="card-body">
                        <DocsHeader />
                        {renderInvestments()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contracts;